import { DocumentCategory } from '~/types/Enums';
import { DocumentType } from './types';
import type { DocumentTypes } from '~/composables/useDocumentType';

export default {
  [DocumentCategory.DEMANDE_AVP]: [
    { ...DocumentType.CADASTRE, required: false },
    { ...DocumentType.FICHE_DE_DEMANDE_AVP, required: false },
    { ...DocumentType.PLAN_DE_DISTRIBUTION, required: false },
    { ...DocumentType.PLAN_DE_LEXISTANT, required: false },
    { ...DocumentType.PLAN_DE_MASSE, required: false },
    { ...DocumentType.PLAN_DIMPLANTATION, required: false },
    { ...DocumentType.PLAN_DES_ZONES_ARGILES, required: false },
    { ...DocumentType.PLAN_DE_SITUATION, required: false },
    { ...DocumentType.PLU, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.DEMANDE_ND]: [
    { ...DocumentType.CADASTRE, required: false },
    { ...DocumentType.FICHE_DE_DEMANDE_AVP, required: false },
    { ...DocumentType.PLAN_DE_DISTRIBUTION, required: false },
    { ...DocumentType.PLAN_DE_LEXISTANT, required: false },
    { ...DocumentType.PLAN_DE_MASSE, required: false },
    { ...DocumentType.PLAN_DIMPLANTATION, required: false },
    { ...DocumentType.PLU, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.VT]: [
    { ...DocumentType.CR_VT, required: false },
    { ...DocumentType.ETUDE_DE_SOL_G1, required: false },
    { ...DocumentType.ETUDE_DE_SOL_G2, required: false },
    { ...DocumentType.PHOTO_VT, required: false },
    { ...DocumentType.PLAN_TECHNIQUE, required: false },
    { ...DocumentType.RELEVE_VT, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.AVP]: [
    { ...DocumentType.FICHE_DE_PRIX, required: false },
    { ...DocumentType.PERSPECTIVE, required: false },
    { ...DocumentType.PLAN_DIMPLANTATION, required: false },
    { ...DocumentType.PLAN, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.CONSTRUCTION_VERSION]: [
    { ...DocumentType.PERSPECTIVE, required: false },
    { ...DocumentType.PLAN, required: false },
    { ...DocumentType.VISUEL, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.CONTRAT]: [
    { ...DocumentType.AVENANT_SIGNE, required: false },
    { ...DocumentType.CONTRAT_TRAVAUX, required: true },
    { ...DocumentType.DEMANDE_AVENANT, required: false },
    { ...DocumentType.FICHE_CONTRAT, required: false },
    { ...DocumentType.NDD_SIGNEE, required: true },
    { ...DocumentType.PERSPECTIVE, required: true },
    { ...DocumentType.PLAN_CADASTRAL, required: false },
    { ...DocumentType.PLAN_DE_BORNAGE, required: false },
    { ...DocumentType.PLAN_DE_DISTRIBUTION, required: true },
    { ...DocumentType.PLAN_DE_MASSE, required: true },
    { ...DocumentType.PLAN_DE_SITUATION, required: true },
    { ...DocumentType.PLAN_DIMPLANTATION, required: true },
    { ...DocumentType.POUVOIR, required: true },
    { ...DocumentType.RAR_CONTRAT, required: false },
    { ...DocumentType.RECEPISSE_RAR, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.PC]: [
    { ...DocumentType.AFFICHAGE_PC_DP, required: false },
    { ...DocumentType.ARRETE_PC_DP, required: false },
    { ...DocumentType.CCT, required: true },
    { ...DocumentType.CERFA_SIGNE, required: false },
    { ...DocumentType.CERFA, required: false },
    { ...DocumentType.DEMANDE_PIECES_COMPLEMENTAIRES, required: false },
    { ...DocumentType.ETUDE_HYDROGEOLOGIQUE, required: false },
    { ...DocumentType.ETUDE_THERMIQUE_BBIO, required: false },
    { ...DocumentType.HUISSIER, required: false },
    { ...DocumentType.PLANS_DE_BORNAGE_CADASTRAL_DE_SITUATION, required: false },
    { ...DocumentType.PLANS_PC_DP_SIGNES_CLIENTS, required: false },
    { ...DocumentType.PLANS_PC_DP_SIGNES_PIECES_COMPLEMENTAIRES, required: false },
    { ...DocumentType.PLANS_PC_DP, required: false },
    { ...DocumentType.PROCURATION_MANDAT_DE_DEPOT_PC_DP, required: false },
    { ...DocumentType.RECEPISSE_DEPOT_PC_DP, required: false },
    { ...DocumentType.RECEPISSE_DEPOT_PIECES_COMPLEMENTAIRES, required: false },
    { ...DocumentType.REFUS_PC_DP, required: false },
    { ...DocumentType.REGLEMENT_PLU, required: false },
  ],
  [DocumentCategory.FINANCEMENT]: [
    { ...DocumentType.FICHE_FINANCIERE, required: false },
    { ...DocumentType.JUSTIF_APPORT_PERSONNEL, required: true },
    { ...DocumentType.OFFRE_DE_PRET, required: false },
    { ...DocumentType.SIMULATION, required: true },
    { ...DocumentType.AUTRE, required: false },
  ],

  [DocumentCategory.TERRAIN_PROJET]: [
    { ...DocumentType.ACTE_AUTHENTIQUE, required: true },
    { ...DocumentType.SOUS_SEING, required: true },
    { ...DocumentType.TITRE_DE_PROPRIETE, required: true },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.CCI]: [
    { ...DocumentType.BON_DE_CHOIX, required: false },
    { ...DocumentType.CARNET_CONCEPTION_INTERIEURE_SIGNE, required: false },
    { ...DocumentType.CARNET_CONCEPTION_INTERIEURE, required: false },
    { ...DocumentType.DEVIS_CARRELAGE_SANITAIRE, required: false },
    { ...DocumentType.PLAN_CUISINE, required: false },
    { ...DocumentType.PLAN_DE_CALEPINAGE_SDB_SDE, required: false },
    { ...DocumentType.PLAN_ELECTRIQUE, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.EPE]: [
    { ...DocumentType.CR_VT, required: true },
    { ...DocumentType.DEVIS_TERRASSIER, required: false },
    { ...DocumentType.ETUDE_BETON, required: false },
    { ...DocumentType.ETUDE_DE_SOL_G1, required: false },
    { ...DocumentType.ETUDE_DE_SOL_G2, required: false },
    { ...DocumentType.ETUDE_THERMIQUE_DEFINITIVE, required: false },
    { ...DocumentType.PHOTO_VT, required: false },
    { ...DocumentType.PLAN_DE_BORNAGE_DWG, required: false },
    { ...DocumentType.PLAN_TECHNIQUE, required: false },
    { ...DocumentType.PLANS_EXE_NON_SIGNES, required: false },
    { ...DocumentType.PLANS_EXE_SIGNES, required: false },
    { ...DocumentType.RELEVE_VT, required: false },
    { ...DocumentType.AUTRE, required: false },
  ],
  [DocumentCategory.GARANTIES]: [
    { ...DocumentType.ATTESTATION_DOMMAGE_OUVRAGE, required: false },
    { ...DocumentType.ATTESTATION_GARANTIE_DE_LIVRAISON, required: false },
  ],
  [DocumentCategory.SSTTFOUR]: [
    { ...DocumentType.RIB, required: false },
    { ...DocumentType.KBIS, required: false },
    { ...DocumentType.PGC, required: false, validite: true },
    { ...DocumentType.ATTESTATION_VIGILANCE_URSSAF, required: false, validite: true },
    { ...DocumentType.ATTESTATION_SUR_LHONNEUR, required: false, validite: true },
    { ...DocumentType.ASSURANCE_DECENNALE, required: false, validite: true },
    {
      ...DocumentType.ASSURANCE_RC,
      required: false,
      validite: true,
      metas: [
        { name: 'assureur', label: 'Assureur', value: '' },
        { name: 'numero_societaire', label: "Numéro de police d'assurance", value: '' },
      ],
    },
  ],
  [DocumentCategory.PRECOMMANDE]: [{ ...DocumentType.DEVIS_SSTTFOUR, required: true }],
  [DocumentCategory.TERRAIN]: [{ ...DocumentType.DOCUMENT_TERRAIN, required: false }],
  [DocumentCategory.PHOTO_TERRAIN]: [{ ...DocumentType.PHOTO_TERRAIN, required: false }],
  [DocumentCategory.PREPACHANTIER]: [
    { ...DocumentType.CR_VTC, required: false },
    { ...DocumentType.CR_RPO, required: false },
    { ...DocumentType.DROC, required: false },
  ],
  [DocumentCategory.COMMANDE]: [
    { ...DocumentType.AUTRE, required: false },
    { ...DocumentType.BDC_SIGNE, required: false },
    { ...DocumentType.BDC, required: false },
    { ...DocumentType.BON_DE_CHOIX, required: false },
    { ...DocumentType.DEVIS_SSTTFOUR, required: false },
    { ...DocumentType.ETUDE_VMC, required: false },
    { ...DocumentType.PLANS_EXE_NON_SIGNES, required: false },
    { ...DocumentType.PLANS_EXE_SIGNES, required: false },
  ],
  [DocumentCategory.COMMANDE_HORS_TRAVAUX]: [
    { ...DocumentType.AUTRE, required: false },
    { ...DocumentType.BDC_SIGNE, required: false },
    { ...DocumentType.BDC, required: false },
    { ...DocumentType.DEVIS_SSTTFOUR, required: false },
  ],
  [DocumentCategory.CHANTIER]: [
    { ...DocumentType.AUTRE, required: false },
    { ...DocumentType.PHOTO, required: false },
    { ...DocumentType.PLAN_FOURNISSEUR, required: false },
    { ...DocumentType.PLANNING_CHANTIER, required: false },
    { ...DocumentType.PROCES_VERBAL, required: false },
    { ...DocumentType.PROCES_VERBAL_RESERVES, required: false },
    { ...DocumentType.PROCES_VERBAL_AUTRE, required: false },
    { ...DocumentType.HOMEBOARDING, required: false },
    { ...DocumentType.RECEPTION, required: false },
    { ...DocumentType.SECURITE, required: false },
    { ...DocumentType.VT, required: false },
    { ...DocumentType.COURRIER_CLIENT, required: false },
  ],
} as DocumentTypes;
